.css-h67k8 .css-1ycyyax {
    margin-top: 5em;
}

.lightBox {
    font-family: 'Arizonia';
    font-size: 5em;
    margin: .1em;
    text-align: center;
}

.gallery-sub {
    font-family: 'Libre Baskerville', serif;
    color: darkslategray;
    float: right;
    opacity: .7;
}