.navbar {
    font-family: 'Libre Baskerville', serif;
    -webkit-transition: background-color 500ms linear;
    transition: background-color 500ms linear;
}

.navbar-brand {
    padding: 0 5px;
    /* border: 2px solid white; */
}

#db-logo {
    height: 3em;
    opacity: .6;
    
}

.item-click {
    cursor: pointer;
    background-color: rgba(88, 88, 88, 0.8);
    margin: 1px;
    border-radius: 4px;
}

.active {
    padding-bottom: 8%;
    border-bottom: 2px white solid;
}
@media only screen and (max-width: 991px) {
    .nav-item {
        text-align: right;
    }
    .active {
        padding-bottom: 1%;
    }
}

.navbar-toggler {
    cursor: pointer;
    background-color: rgba(88, 88, 88, 0.5);
    margin: 1px;
    border-radius: 4px;
}
.nav-link {
    text-align: center;
    /* font-family: 'Rubik'; */
    font-size: 1.1em;
    width: 5.5em;
    float: right;
    color: #e7e7e5;
    text-shadow: 
      /* 0 -1px 0 #fff,  */
      0 1px 0 #2e2e2e, 
      0 5px 10px rgba(0, 0, 0, 0.9);
}

body {
    line-height: 1.4;
}

.home {
    font-family: 'Libre Baskerville', serif;
    background: linear-gradient(150deg, rgba(255, 204, 36, 0.171), rgb(255, 255, 255));

}
.display-1 {
    font-family: 'Cormorant Garamond', serif;
    font-weight: 700;
    font-size: 8rem;
    color: #e7e7e5;
    text-shadow: 
      0 -1px 0 #fff, 
      0 1px 0 #2e2e2e, 
      0 2px 0 #2c2c2c, 
      0 3px 0 #2a2a2a, 
      0 4px 0 #282828, 
      0 5px 10px rgba(0, 0, 0, 0.9);
}
@media(max-width: 1200px){
    .display-1{
        font-size: 5em;
    }
    .message {
        font-size: 2em;
        padding: 30px 0 30px 0;
    }
    .addSparkle {
        font-size: .8em;
    }
}
@media(max-width: 800px){
    .display-1{
        font-size: 4em;
    }
    .message {
        font-size: 2em;
        padding: 30px 0 30px 0;
    }
}

.addSparkle {
    color: #4d4d4d;
    text-shadow: 
      0 -1px 0 #fff, 
      0 1px 0 #2e2e2e, 
      0 2px 0 #2c2c2c, 
      0 5px 20px rgba(255, 193, 59, 0.7);
}

.byNicoleK {
    font-family: 'Kaushan Script';
    font-size: 2.2em;
    letter-spacing: .05em;
    color: #e0dfdc;
    text-shadow: 
      0 -1px 0 #fff, 
      0 1px 0 #2e2e2e, 
      0 2px 0 #2c2c2c, 
      0 3px 0 #2a2a2a, 
      0 4px 0 #282828, 
      0 5px 10px rgba(0, 0, 0, 0.9);
}

.hero {
    width: 100vw;
    height: 100vh;
    color: white;

    /* flexbox */
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: right;

    /* Background styles */
    background-image: -webkit-gradient(linear,left top, left bottom,from(rgba(27, 16, 0, 0.7)),to(rgba(0, 0, 0, 0.1))), url(/img/dbBanner1.jpg);
    background-image: linear-gradient(rgba(27, 16, 0, 0.7),rgba(0, 0, 0, 0.1)), url(/img/dbBanner1.jpg);
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: scroll;
}

.call-to-action {
    color: white;
    background-color: #21ce99;
}
.btn-rounded {
    padding: 1em;
    border-radius: 50px;
}

.call-to-action:hover {
    color: white;
    background-color: #01ae79;
}

.message {
    padding: 15px 0 30px 0;
    margin: 0 auto;
    text-align: center;
    font-family: 'Arizonia';
    font-size: 5em;
}

.inquireButton {
    text-align: center;
}

.social {
    color: white;
    margin-top: -3.5em;
    margin-bottom: 3.5em;
    font-size: 1.5em;
}

.cornerLink {
    margin: 1.5em;
    color: white;
    opacity: 0.5;
}


@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) { 
    .social {
        margin-top: -6em;
        opacity: 0.5;
        font-size: 2em;
    }
}

@media only screen and (max-device-width : 767px) { 
    .social {
        margin-top: -6em;
    }
}
div {
    overflow: hidden;
}

.container-fluid {
    padding: 0;
}

.box {
    width: 90%;
    color: white;
}

.box-right {
    width: 80%;
    color: white;
}

.spacing {
    margin-bottom: 2em;
}

.box-padding {
    padding: 20px 15px;
}

.om {
	background-image: -webkit-gradient(linear,left top, left bottom,from(rgba(0, 0, 0, 0.3)),to(rgba(0, 0, 0, 0.5))), url(/img/banner1.jpg);
	background-image: linear-gradient(rgba(0, 0, 0, 0.3),rgba(0, 0, 0, 0.5)), url(/img/banner1.jpg);
	background-position: 43% 60%;
	opacity: .85;
}
.au {
	margin-top: 1em;
	background-image: -webkit-gradient(linear,left top, left bottom,from(rgba(0, 0, 0, 0.3)),to(rgba(0, 0, 0, 0.5))), url(/img/gallery/h3.jpg);
	background-image: linear-gradient(rgba(0, 0, 0, 0.3),rgba(0, 0, 0, 0.5)), url(/img/gallery/h3.jpg);
	background-position: 40% 70%;
	background-size: cover;
	opacity: .85;
}
.bl {
    background-image: -webkit-gradient(linear,left top, left bottom,from(rgba(0, 0, 0, 0.3)),to(rgba(0, 0, 0, 0.5))), url(/img/bracelets.jpg);
    background-image: linear-gradient(rgba(0, 0, 0, 0.3),rgba(0, 0, 0, 0.5)), url(/img/bracelets.jpg);
	background-position: 40% 100%;
	background-size: cover;
	opacity: .80;
	background-repeat: no-repeat;
}

.nl {
    background-image: -webkit-gradient(linear,left top, left bottom,from(rgba(0, 0, 0, 0.3)),to(rgba(0, 0, 0, 0.5))), url(/img/nl.jpg);
    background-image: linear-gradient(rgba(0, 0, 0, 0.3),rgba(0, 0, 0, 0.5)), url(/img/nl.jpg);
	background-position: left 0%;
	background-size: cover;
	opacity: .80;
	background-repeat: no-repeat;}

.cj {
	background-image: -webkit-gradient(linear,left top, left bottom,from(rgba(0, 0, 0, 0.3)),to(rgba(0, 0, 0, 0.5))), url(/img/cj.jpg);
	background-image: linear-gradient(rgba(0, 0, 0, 0.3),rgba(0, 0, 0, 0.5)), url(/img/cj.jpg);
	background-position: 30%;
	background-size: cover;
	opacity: .80;
	background-repeat: no-repeat;
}

.heading-about {
	margin: 0 .7em;
	text-align: left;
	font-family: 'Arizonia';
	font-size: 5em;
	color: #e7e7e5;
    text-shadow: 
      0 -1px 0 #fff, 
      0 1px 0 #2e2e2e, 
      0 2px 0 #2c2c2c, 
      0 3px 0 #2a2a2a, 
      0 4px 0 #282828, 
      0 5px 10px rgba(0, 0, 0, 0.9);
}

.heading-about-right {
	margin: 0 .5em;
	text-align: right;
	font-family: 'Arizonia';
	font-size: 5em;
	color: #e7e7e5;
    text-shadow: 
      0 -1px 0 #fff, 
      0 1px 0 #2e2e2e, 
      0 2px 0 #2c2c2c, 
      0 3px 0 #2a2a2a, 
      0 4px 0 #282828, 
      0 5px 10px rgba(0, 0, 0, 0.9);
}

@media(max-width: 1200px){
	.heading-about {
		margin: 0 1em;
		text-align: left;
		font-family: 'Arizonia';
		font-size: 5em;
		line-height: 1.2em;
	}
	.heading-about-right {
		margin: 0 1em;
		text-align: right;
		font-family: 'Arizonia';
		font-size: 5em;
		line-height: 1.2em;
	}
}
@media(max-width: 850px){
	.heading-about {
		margin: 0em .2em;
		text-align: left;
		font-family: 'Arizonia';
		font-size: 4em;
		line-height: 1.2em;
	}
	.heading-about-right {
		margin: 0em ;
		text-align: right;
		font-family: 'Arizonia';
		font-size: 4em;
		line-height: 1.2em;
	}
}
.about-message {
	margin: 5px;
}
.about-subtitle {
    margin-bottom: 15px;
}
hr { 
    display: block;
	margin-top: 1.5em;
	margin-bottom: 1.5em;
	border-style: inset;
	border-width: 1px;
}

.description {
	font-family: 'Rubik';
	font-size: 1.1em;
	font-weight: bold;
	margin-bottom: 0;
	color: #ffffff;
    text-shadow: 
      0 1px 0 #2e2e2e, 
      0 2px 0 #2c2c2c, 
      0 3px 0 #2a2a2a, 
      /* 0 4px 0 #282828,  */
      0 5px 15px rgba(0, 0, 0, 1);
}


@media(min-width: 992px) {
    hr {
        display: none;
    }
}

@media(min-width: 1200px) {
    .box-about {
        padding: 50px 100px;
    }

    .heading-about {
        margin-left: 15%;
    }
    .box-padding {
        padding: 60px 100px;
    }
    .om .au {
		text-align: end;
    }
    .sc {
        text-align: end;
    }
}

.content {
	grid-area: grid;
	margin: 25vh 0 30vh;
}

.content--offset {
	display: grid;
	grid-template-columns: repeat(2, minmax(100px, 1fr));
	grid-gap: 30vh 10vw;
}

.content--full {
	width: 100vw;
	justify-self: center;
}

.content--padded {
	padding: 0 10vw;
}

.content__item {
	--imgwidth: calc(var(--imgwidthmax) * var(--aspect-ratio));
	width: var(--imgwidth);
	max-width: 100%;
	position: relative;
	will-change: transform;
	margin-bottom: 30vh;
	display: grid;
	grid-template-columns: 50% 50%;
}

.content__item--wide {
	grid-template-columns: 20% 80%;
}

.content__item--wide:nth-child(even) {
	grid-template-columns: 80% 20%;
}

.content--center .content__item {
	margin: 0 auto 60vh;
}

.content__item--expand {
	width: 100%;
	grid-template-columns: minmax(0, var(--imgwidth)) 1fr;
}

.content__item--expand:nth-child(even) {
	grid-template-columns: 1fr minmax(0, var(--imgwidth));
}

.content--alternate .content__item {
	max-width: 90vw;
}

.content--offset .content__item {
	margin: 0 auto 15vh;
}

.content--offset .content__item:nth-child(even) {
	align-self: end;
	margin-bottom: -20vh;
}

.content--alternate .content__item:nth-child(even) {
	margin-left: auto;
}

.content__item-imgwrap {
	position: relative;
	--imgwidth: 100%;
	margin: 0 auto;
	grid-area: 1 / 1 / 3 / 3;
	overflow: hidden;
	width: var(--imgwidth);
	padding-bottom: calc(var(--imgwidth) / (var(--aspect-ratio)));
	will-change: transform;
}

.demo-2 .content__item-imgwrap {
	outline: 1px solid transparent;
}

.content__item--expand .content__item-imgwrap {
	grid-area: 1 / 1 / 3 / 2;
}

.content__item--expand:nth-child(even) .content__item-imgwrap {
	grid-area: 1 / 2 / 3 / 3;
	justify-self: end;
}

.content__item-img {
	--overflow: 40px;
	height: calc(100% + (2 * var(--overflow)));
	top: calc( -1 * var(--overflow));
	width: 100%;
	position: absolute;
	background-size: cover;
	background-position: 50% 0%;
	will-change: transform;
	opacity: 0.8;
}

.content__item-img--t1 {
	--overflow: 60px;
}

.content__item-img--t2 {
	--overflow: 80px;
}

.content__item-img--t3 {
	--overflow: 120px;
}

.content__item-number {
	opacity: 0.03;
	font-size: 25vw;
	position: absolute;
	top: -7vw;
	right: -10vw;
	line-height: 1;
}

.content__item:nth-child(even) .content__item-number {
	right: auto;
	left: -7vw;
}

.content__item-title {
	position: relative;
	font-size: var(--size-title);
	padding: 0 3vw;
	margin: calc(var(--size-title) * -1/2) 0 0 0;
	align-self: start;
	line-height: 1;
	font-family: var(--font-title);
	font-weight: var(--font-weight-title);
	color: var(--color-title);
	will-change: transform;
	mix-blend-mode: var(--blendmode-title);
}

.content--center .content__item-title {
	grid-area: 1 / 1 / 3 / 3;
	margin: auto;
}

.content__item-title--layer {
	padding: 0;
	z-index: 10;
	grid-area: 1 / 1 / 2 / 3;
	width: 100%;
	text-align: center;
}

.content__item-description {
	grid-area: 3 / 1 / 3 / 3;
	width: 70%;
	position: relative;
	margin: 0;
	padding: 1rem 0 0 0;
	color: var(--color-description);
}

.content--alternate .content__item-title,
.content__item--wide:nth-child(even) .content__item-description {
	grid-area: 3 / 1 / 4 / 2;
	justify-self: start;
}

.content--alternate  .content__item:nth-child(even) .content__item-title,
.content__item--wide .content__item-description {
	grid-area: 3 / 2 / 4 / 3;
	justify-self: end;
	width: auto;
}

.content__item--expand .content__item-description {
	grid-area: 1 / 2 / 3 / 3;
    justify-self: start;
    align-self: start;
    padding: 0 2rem;
    width: 250px;
    font-size: 0.9rem;
}

.content__item--expand:nth-child(even) .content__item-description {
	grid-area: 1 / 1 / 3 / 2;
	justify-self: end;
	text-align: right;
}

.content__item-deco {
	position: absolute;
	top: 2rem;
	left: 10vw;
	height: 30%;
	width: 1px;
	background: #d79612;
}

.content__item-decobar {
	width: 140%;
	height: 25%;
	top: 20%;
	left: -20%;
	position: absolute;
	background: #dd525a;
	mix-blend-mode: color-burn;
}

@media screen and (min-width: 53em) {
	.page--layout-1 {
		grid-template-columns: 30% 70%;
		grid-template-areas: 'header header' '...  meta' 'grid grid';
	}
	.page--layout-2 {
		grid-template-columns: 1fr 1fr;
		grid-template-areas: 'header meta' '...  meta' 'grid grid';
	}
}

@media screen and (max-width: 40em) {
	.content__item--expand .content__item-imgwrap,
	.content__item--expand:nth-child(even) .content__item-imgwrap {
		grid-area: 1 / 1 / 3 / 3;
	}
	.content__item--expand .content__item-description,
	.content__item--expand:nth-child(even) .content__item-description,
	.content__item--wide .content__item-description,
	.content__item--wide:nth-child(even) .content__item-description {
		grid-area: 3 / 1 / 4 / 3;
	    padding: 1rem 0;
	    width: 100%;
	    text-align: left;
	}
	.content__item--wide .content__item-description {
		padding: 1rem;
	}
	.content--alternate .content__item-title,
	.content--alternate .content__item:nth-child(even) .content__item-title {
		grid-area: 1 / 1 / 4 / 2;
	}
}

#map {
    width: 100%;
    height: 300px;
    flex-direction: column;
    min-height: 300px;
    background-color: grey;
    display: flex;
}
.address{
    margin: 20px;
    font-size: 1.2em;
}

.contact-info {
    margin: 20px;
    font-size: 1.2em;
    font-family: 'Rubik';
}
.sameline {
    white-space: nowrap;
    margin-bottom: .5em;
}
.padding-auto {
    padding: auto;
}
.min-width {
    min-width: 80px;
}
/* .text-left-align {
    text-align: left;
} */
.align-center {
    display: flex;
    align-items: center;
}
.horizontal-center {
    margin: 0 auto;
    text-align: right;
}
.margin-bottom-md {
    margin-bottom: 3rem;
}
.contact {
    /* font-family: 'Arizonia';
    font-size: 6em;
    width: 100vw;
    height: 20vh;
    color: white; */

    /* flexbox */
    /* display: flex;
    justify-content: center;
    align-items: center;
    text-align: right; */

    /* Background styles */
    /* background-image: linear-gradient(rgba(0, 0, 0, 0.3),rgba(0, 0, 0, 0.5)), url(/img/gallery/i4.jpg);
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed; */
}

.lightBox {
    font-family: 'Arizonia';
    font-size: 6em;
    margin: .1em;
    text-align: center;
    
}

.google-maps-iframe {
    width: 100%;
    height: 100%;
    border: 0;
    flex-grow: 1;
    margin: 0;
    padding: 0;
}
#ay-logo {
    max-width: 2em;
}
.textwl {
    text-align: center;
    margin-bottom: .8em;
}
.textwl a {
    color: black;
    opacity: .4;
    text-decoration: none;
    text-shadow: rgba(0, 0, 0, 0.781);
}
.textwl a:hover {
    opacity: 1;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    text-decoration: none;

}
.maplinks {
    margin-top: 1.5em;
    /* margin-right: 0em; */

}
@media only screen and (min-width: 576px) {
    .address, .contact-info, .hours-of-op {
        margin: 0;
    }
    .address, .contact-info {
        padding-left: 10px;
        text-align: left;
    }
    .enough-space {
        padding: 0;
    }
    .enough-space.left {
        padding-right: 10px;
    }
    .enough-space.right {
        padding-left: 10px;
    }
    .table-hours {
        width: auto;
    }
    .hours {
        margin-top: 0;
    }
}
@media only screen and (min-width: 768px) {
    .address, .contact-info, .hours-of-op {
        margin: 10px;
    }
    .address, .contact-info {
        padding-left: 0px;
        text-align: right;
        word-spacing: .2em;
    }

    #map {
        display: block;
    }
}

.css-h67k8 .css-1ycyyax {
    margin-top: 5em;
}

.lightBox {
    font-family: 'Arizonia';
    font-size: 5em;
    margin: .1em;
    text-align: center;
}

.gallery-sub {
    font-family: 'Libre Baskerville', serif;
    color: darkslategray;
    float: right;
    opacity: .7;
}
