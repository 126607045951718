#map {
    width: 100%;
    height: 300px;
    flex-direction: column;
    min-height: 300px;
    background-color: grey;
    display: flex;
}
.address{
    margin: 20px;
    font-size: 1.2em;
}

.contact-info {
    margin: 20px;
    font-size: 1.2em;
    font-family: 'Rubik';
}
.sameline {
    white-space: nowrap;
    margin-bottom: .5em;
}
.padding-auto {
    padding: auto;
}
.min-width {
    min-width: 80px;
}
/* .text-left-align {
    text-align: left;
} */
.align-center {
    display: flex;
    align-items: center;
}
.horizontal-center {
    margin: 0 auto;
    text-align: right;
}
.margin-bottom-md {
    margin-bottom: 3rem;
}
.contact {
    /* font-family: 'Arizonia';
    font-size: 6em;
    width: 100vw;
    height: 20vh;
    color: white; */

    /* flexbox */
    /* display: flex;
    justify-content: center;
    align-items: center;
    text-align: right; */

    /* Background styles */
    /* background-image: linear-gradient(rgba(0, 0, 0, 0.3),rgba(0, 0, 0, 0.5)), url(/img/gallery/i4.jpg);
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed; */
}

.lightBox {
    font-family: 'Arizonia';
    font-size: 6em;
    margin: .1em;
    text-align: center;
    
}

.google-maps-iframe {
    width: 100%;
    height: 100%;
    border: 0;
    flex-grow: 1;
    margin: 0;
    padding: 0;
}
#ay-logo {
    max-width: 2em;
}
.textwl {
    text-align: center;
    margin-bottom: .8em;
}
.textwl a {
    color: black;
    opacity: .4;
    text-decoration: none;
    text-shadow: rgba(0, 0, 0, 0.781);
}
.textwl a:hover {
    opacity: 1;
    transition: all 0.3s ease-in-out;
    text-decoration: none;

}
.maplinks {
    margin-top: 1.5em;
    /* margin-right: 0em; */

}
@media only screen and (min-width: 576px) {
    .address, .contact-info, .hours-of-op {
        margin: 0;
    }
    .address, .contact-info {
        padding-left: 10px;
        text-align: left;
    }
    .enough-space {
        padding: 0;
    }
    .enough-space.left {
        padding-right: 10px;
    }
    .enough-space.right {
        padding-left: 10px;
    }
    .table-hours {
        width: auto;
    }
    .hours {
        margin-top: 0;
    }
}
@media only screen and (min-width: 768px) {
    .address, .contact-info, .hours-of-op {
        margin: 10px;
    }
    .address, .contact-info {
        padding-left: 0px;
        text-align: right;
        word-spacing: .2em;
    }

    #map {
        display: block;
    }
}
