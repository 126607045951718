.navbar {
    font-family: 'Libre Baskerville', serif;
    -webkit-transition: background-color 500ms linear;
    -ms-transition: background-color 500ms linear;
    transition: background-color 500ms linear;
}

.navbar-brand {
    padding: 0 5px;
    /* border: 2px solid white; */
}

#db-logo {
    height: 3em;
    opacity: .6;
    
}

.item-click {
    cursor: pointer;
    background-color: rgba(88, 88, 88, 0.8);
    margin: 1px;
    border-radius: 4px;
}

.active {
    padding-bottom: 8%;
    border-bottom: 2px white solid;
}
@media only screen and (max-width: 991px) {
    .nav-item {
        text-align: right;
    }
    .active {
        padding-bottom: 1%;
    }
}

.navbar-toggler {
    cursor: pointer;
    background-color: rgba(88, 88, 88, 0.5);
    margin: 1px;
    border-radius: 4px;
}
.nav-link {
    text-align: center;
    /* font-family: 'Rubik'; */
    font-size: 1.1em;
    width: 5.5em;
    float: right;
    color: #e7e7e5;
    text-shadow: 
      /* 0 -1px 0 #fff,  */
      0 1px 0 #2e2e2e, 
      0 5px 10px rgba(0, 0, 0, 0.9);
}